/* Bootstrap v5.0 */
//@import "~bootstrap/scss/bootstrap";

@import "../bootstrap/variables";
@import "../bootstrap/style";

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~@fortawesome/fontawesome-free/css/v4-shims.css';

@import "../eleganticons/variables";
@import "../eleganticons/style";
//@import "../fontawesome/variables";
//@import "../fontawesome/style";
@import "../lineaicons/variables";
@import "../lineaicons/style";
@import "../revslider/variables";
@import "../revslider/style";

/* pick the Lato fonts individually to avoid importing the entire font family */
$lato-font-path: '~lato-font/fonts';
@import '~lato-font/scss/public-api';
@include lato-include-font('normal');
@include lato-include-font('bold');

@import "variables";
@import "hover-min.css";
@import "animate.css";
@import "owl.carousel.css";
@import "jquery.fancybox.css";
@import "spacing.scss";
@import "style.scss";
