﻿@charset "utf-8";
/*
Master Stylesheet Template:  iCon - Responsive | One Page Parallax HTML5 Template
Author: Theme Lite
URL: http://themeforest.net/user/Theme_Lite Version:  1.0
*/

/* CSS Document
Table Of Content

+General Styling and Tags
*body
*Button Effect Color
+Heading

+loader

+More Style Button

+Style Button

+Header Start
  -Transparent Header
  -Fixed white header

+navigation
  -navbar-default
  -push navigation

+Slider + text Rotater
  -main Slier
  -Text Slider

+ Right Side Line moduleControl

+ About canvas Box

+ Paralax background

+ Work Process

+ Counters Fact Info

+ Our Team

+ Portfolio

+ Pricing Tables

+ skill Bar

+ Testinomials

+ Our Client

+ blog
  - blog
  - Blog Details
  - Signle Page
  - Blog SideBar

+ Contact

+ Footer

+ media query css

*/
/****** General Styling ******/
body{
  color:$grey-5;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
  font-size:17px;
}
a {
  color:$primary;
  text-decoration:none;
  outline:none;
}
a:hover ,
a:focus{
  text-decoration:none;
  outline:none;
  color:$grey-2;
}
input[type="submit"]{
  background-color:transparent;
}
button:focus {
    outline: medium none;
}

/*Button Effect Style Color*/
.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0,0,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
  background: $primary;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}
.hvr-radial-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $primary;
  border-radius: 100%;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}
.hvr-rectangle-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $primary;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0,0,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: $primary;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}
.hvr-shutter-in-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $primary;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0,0,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: $primary;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}
.hvr-shutter-in-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $primary;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}
.hvr-shutter-out-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $primary;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}
.hvr-border-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 4px $primary, 0 0 1px rgba(0,0,0,0)
}
.hvr-hollow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: background;
  transition-property: background;
  box-shadow: inset 0 0 0 4px $primary, 0 0 1px rgba(0,0,0,0)
}
.hvr-ripple-out:before {
  content: '';
  position: absolute;
  border: $primary solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s
}
.hvr-ripple-in:before {
  content: '';
  position: absolute;
  border: $primary solid 4px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s
}
.hvr-outline-out:before {
  content: '';
  position: absolute;
  border: $primary solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left
}
.hvr-outline-in:before {
  pointer-events: none;
  content: '';
  position: absolute;
  border: $primary solid 4px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  left: -16px;
  opacity: 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left
}
.hvr-bubble-top:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  left: calc(50% - 10px);
  top: 0;
  border-width: 0 10px 10px;
  border-color: transparent transparent $primary
}
.hvr-bubble-right:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  top: calc(50% - 10px);
  right: 0;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent $primary
}
.hvr-bubble-bottom:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  left: calc(50% - 10px);
  bottom: 0;
  border-width: 10px 10px 0;
  border-color: $primary transparent transparent
}
.hvr-bubble-left:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  top: calc(50% - 10px);
  left: 0;
  border-width: 10px 10px 10px 0;
  border-color: transparent $primary transparent transparent
}
.hvr-bubble-float-top:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  top: 0;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent $primary;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform
}
.hvr-bubble-float-right:before {
  position: absolute;
  z-index: -1;
  top: calc(50% - 10px);
  right: 0;
  content: '';
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent $primary;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform
}
.hvr-bubble-float-bottom:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  bottom: 0;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: $primary transparent transparent;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform
}
.hvr-bubble-float-left:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: calc(50% - 10px);
  left: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent $primary transparent transparent;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform
}

h1, h2, h3, h4,
#testinomial-slider .item h5{
  margin:0;
}
h1{
  font-size:46px;
}
/* h2, h3, h4{
  text-transform:capitalize;
} */
h2{
  font-size:30px;
}
h3{
  font-size:24px;
  font-weight: 600;
}
h4{
  font-size:18px;
  /* text-transform: capitalize; */
    font-weight: 600;
}
p{
  font-size:17px;
  color:$grey-5;
}
textarea{
  resize:none;
}
.divider {
    margin: auto;
    width: 80px;opacity: .5;
}

.dv-bottom {display: block;margin: auto;
    background-color: #999999;
    width: 30px; cursor:inherit;height: 1px;
}
.dv-top {display: block;
    background-color: #888888;
    width: 30px; cursor:inherit;height: 1px;margin: auto;
}
.dv-middle{
  background-color: $primary;
  display: block;
  height: 1px;
  margin: 2px auto 2px;
  padding: 0;
  width: 60px;
}
p.title{
  color: #888888;
  font-size: 14px;
  position: relative;

}

.head-title{
  margin-bottom:40px; color:$grey-4;letter-spacing: 1px;
}
.headline {
    margin-bottom: 30px;
    text-transform: uppercase;
}
.headline::after {
    border-bottom: 2px solid $primary;
    content: "";
    display: block;
    width: 30px;
}
.padding{
  padding:90px 0;
}
.top-padding{
  padding-top:90px;
}

.section-padding{
  padding-top:130px!important;
}

.padding-botom{
  padding-bottom:90px;
}
.magin-bottom{
  margin-bottom:30px;
}
/* ===== Begin Stylesheet Switcher (for demo only). Remove if you do not need it. ===== */
.switcher-wrapper {
  display: inline-block;
  position: fixed;
  top: 20%;
  left: -160px;
  z-index: 99999;
}
.switcher {
  width: 158px;
  background-color: $white;
  padding: 15px;
  text-align: center;
  font-size: 15px;
  color: $grey-2;
}
.switcher.pull-left > p {
  font-family: raleway;
}
.switcher-box {
  float: left;
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 100px;
}
.switcher-box:hover {
  border: 1px solid $white;
}
.switcher-btn {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  color: $white;
  background-color: $grey-2;
  border-left: 0;
}
.switcher-box.default {
  background-color:#ff072a;
}
.switcher-box.style-1 {
  background-color: $blue;
}
.switcher-box.style-2 {
  background-color: $green;
}
.switcher-box.style-3 {
  background-color: $purple;
}
.switcher-box.style-4 {
  background-color: $orange;
}
.switcher-box.style-5 {
  background-color: $indigo;
}
/* ===== End Stylesheet Switcher (for demo only). Remove if you do not need it. ===== */

/*loader*/
.page-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: hidden;
  background-color: $white;
}
.loader {
  height: 14px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  width: 80px;
}
.dot {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 100%!important;
  background: #1B1D1F;
  position: absolute;
}
.dot_1 {
  animation: animateDot1 1.5s linear infinite;
  left: 16px;
  background: $primary;
}
.dot_2 {
  animation: animateDot2 1.5s linear infinite;
  animation-delay: 0.5s;
  left: 28px;
}
.dot_3 {
  animation: animateDot3 1.5s linear infinite;
  left: 16px;
}
.dot_4 {
  animation: animateDot4 1.5s linear infinite;
  animation-delay: 0.5s;
  left: 28px;
}
@keyframes animateDot1 {
0% {
transform: rotate(0deg) translateX(-12px);
}
25% {
transform: rotate(180deg) translateX(-12px);
}
75% {
transform: rotate(180deg) translateX(-12px);
}
100% {
transform: rotate(360deg) translateX(-12px);
}
}
@keyframes animateDot2 {
0% {
transform: rotate(0deg) translateX(-12px);
}
25% {
transform: rotate(-180deg) translateX(-12px);
}
75% {
transform: rotate(-180deg) translateX(-12px);
}
100% {
transform: rotate(-360deg) translateX(-12px);
}
}
@keyframes animateDot3 {
0% {
transform: rotate(0deg) translateX(12px);
}
25% {
transform: rotate(180deg) translateX(12px);
}
75% {
transform: rotate(180deg) translateX(12px);
}
100% {
transform: rotate(360deg) translateX(12px);
}
}
@keyframes animateDot4 {
0% {
transform: rotate(0deg) translateX(12px);
}
25% {
transform: rotate(-180deg) translateX(12px);
}
75% {
transform: rotate(-180deg) translateX(12px);
}
100% {
transform: rotate(-360deg) translateX(12px);
}
}

/*button*/
.btn-black{background-color: #000; color:$white;}
.btn-black:focus, .btn-black:hover{color:#888888;}
.btn-black::before, .btn-black:focus::before, .btn-black:hover::before{background-color: $white;}

.btn-red{background-color: $primary; color:$white;}
.btn-red:focus, .btn-red:hover{color:$white;}
.btn-red::before, .btn-red:focus::before, .btn-red:hover::before{background-color: #000;}

.btn-white{background-color: $white; color:#444;}
.btn-white:focus, .btn-white:hover{color:$white;}
.btn-white::before, .btn-white:focus::before, .btn-white:hover::before{background-color: #444;color: $white;}


/*more*/
a.more {
    background-color: $grey-3;
    border-color: $grey-3;
    color: $white;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    position: absolute;
    right: 5%;
    top: 20px;
    width: 150px; text-align:center;}
a.more:hover::before {
    background: $white none repeat scroll 0 0;
    left: 88px;
    width: 20px;
}
a.more i {
    font-size: 20px;
    position: relative;
    top: 3px;
}
a.more i{
    color: rgba(255, 255, 255, 0);
    font-size: 24px;
    text-indent: -10px;
    transition: all 0.3s ease-out 0s;
}
a.more:hover {
    background-color: $primary;
    border-color: $primary;
}
a.more:hover i {
    color: $white; text-indent: 24px;}
  a.more:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    text-align: center;
    text-indent: 0;
}

.text-soft,
.text-soft a { color: #f4f4f4; }
.text-muted,
.text-muted a { color: #777; }
.text-success,
.text-success a { color: #3c763d }
.text-info,
.text-info a { color: #27c4f3 }
.text-warning,
.text-warning a { color: #ff9a0c; }
.text-danger,
.text-danger a { color: #ff5954 }
.text-black,
.text-black a { color: $black; }
.text-white,
.text-white a { color: $white; }
.text-green,
.text-green a { color: #4cae4c; }
.text-purple,
.text-purple a { color: $purple; }
.text-magenta,
.text-magenta a { color: #fd18f3; }
.text-red,
.text-red a { color: $red; }
.text-yellow,
.text-yellow a { color: #ffe205; }
.text-orange,
.text-orange a { color: $orange; }
.text-blue,
.text-blue a { color: $blue; }
.text-teal,
.text-teal a { color: #5dcc89; }
.text-turquoise,
.text-turquoise a { color: #02bfab; }
.text-pink,
.text-pink a { color: #ed5094; }

.text-grey-1,
.text-grey-1 a { color: $grey-1; }
.text-grey-2,
.text-grey-2 a { color: $grey-2; }
.text-grey-3,
.text-grey-3 a { color: $grey-3; }
.text-grey-4,
.text-grey-4 a { color: $grey-4; }
.text-grey-5,
.text-grey-5 a { color: $grey-5; }
.text-grey-6,
.text-grey-6 a { color: $grey-6; }
.text-grey-7,
.text-grey-7 a { color: $grey-7; }
.text-grey-8,
.text-grey-8 a { color: $grey-8; }
.text-grey-9,
.text-grey-9 a { color: $grey-9; }

a.text-soft:focus, a.text-soft:hover,
.text-soft a:focus, .text-soft a:hover { color: #f5f5f5; }
a.text-muted:focus, a.text-muted:hover,
.text-muted a:focus, .text-muted a:hover { color: #5b5b5b; }
a.text-success:focus, a.text-success:hover,
.text-success a:focus, .text-success a:hover,
a.text-green:focus, a.text-green:hover,
.text-green a:focus, .text-green a:hover { color: #399336; }
a.text-info:focus, a.text-info:hover,
.text-info a:focus, .text-info a:hover { color: #1a4c76; }
a.text-warning:focus, a.text-warning:hover,
.text-warning a:focus, .text-warning a:hover { color: #d07d22; }
a.text-danger:focus, a.text-danger:hover,
.text-danger a:focus, .text-danger a:hover { color: #9e2120; }
a.text-purple:focus, a.text-purple:hover,
.text-purple a:focus, .text-purple a:hover { color: #6400be; }
a.text-magenta:focus, a.text-magenta:hover,
.text-magenta a:focus, .text-magenta a:hover { color: #cc00c6; }
a.text-red:focus, a.text-red:hover,
.text-red a:focus, .text-red a:hover { color: #e20000; }
a.text-yellow:focus, a.text-yellow:hover,
.text-yellow a:focus, .text-yellow a:hover { color: #e2bf00; }
a.text-orange:focus, a.text-orange:hover,
.text-orange a:focus, .text-orange a:hover { color: #e98c00; }
a.text-black:focus, a.text-black:hover,
.text-black a:focus, .text-black a:hover { color: $grey-1; }
a.text-white:focus, a.text-white:hover,
.text-white a:focus, .text-white a:hover { color: #fafafa; }
a.text-blue:focus, a.text-blue:hover,
.text-blue a:focus, .text-blue a:hover { color: #255e9e; }
a.text-teal:focus, a.text-teal:hover,
.text-teal a:focus, .text-teal a:hover { color: #4fbb7a; }
a.text-turquoise:focus, a.text-turquoise:hover,
.text-turquoise a:focus, .text-turquoise a:hover { color: #00ac9a; }
a.text-pink:focus, a.text-pink:hover,
.text-pink a:focus, .text-pink a:hover { color: #da4c89; }

.text-bold { font-weight: 600;}
.text-normal { font-weight: normal;}
.text-nowrap { white-space: nowrap;}
.text-underline { text-decoration: underline;}

.bg-soft { background-color: #f4f4f4; }
.bg-white { background-color: $white; }

/*Header Starts*/
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
	background-color: transparent;
}
/* ------------ Transparent Header ------------ */
.header-crystal #header{background-color:transparent;}
.header-crystal #main-slider{color:$white;}
.header-crystal #navigation.affix-top .navbar-default .navbar-nav > li > a {color:$white;}
.header-crystal #navigation.affix-top .navbar-default .navbar-nav > li > a::before {background-color: $white;}
.header-crystal #navigation.affix-top .navbar-default .navbar-nav > li.active > a, .header-crystal #navigation.affix-top .navbar-default .navbar-nav > li:hover > a{color:$primary}
.header-crystal #main-slider .tp-banner-container .default{background-color: white;color: #444;}
.header-crystal #main-slider .tp-banner-container .default:hover{ background-color:$primary;color: $white;}
.header-crystal #main-slider .btn{color:$white;}
.header-crystal #navigation.affix-top{background-color: transparent;}

/* ------------Fixed white header ------------ */
#header{padding:0;position: absolute;z-index: 99999!important;}
#header #navigation{  -webkit-transition: all 0.1s ease 0.1s;
  -moz-transition: all 0.1s ease 0.1s;
  -ms-transition: all 0.1s ease 0.1s;
  -o-transition:all 0.1s ease 0.1s;
  transition:all 0.1s ease 0.1s;}
#header .container-fluid{padding:0 30px;}
a, #navigation.affix .navbar-default .navbar-nav > li > a,
#header, #navigation.affix, .navbar-brand,
#navigation.affix .navbar-default .navbar-nav > li > a,
.push_nav li a,
ul.top-right li a:hover,
.canvas-box span i, .canvas-box h4,
.canvas-box:hover span i,
.we-do .do-wrap i, .we-do .do-wrap:hover i,
.counters-item i, .counters-item:hover i,
.Portfolio-image .overlay,
.work-filter ul li a,
.navigation-button, .navigation-button > button span, .overlay,
.we-do .do-wrap:hover .top, .we-do .do-wrap:hover span,
#paralax-slider .owl-controls .owl-page span{
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition:all .3s ease;
  transition:all .3s ease;
}
#navigation.affix-top{background-color: $white;
    top: 0;
    left: 0;
    right: 0;}
#navigation.affix {
  background-color: $white;
  position: fixed;
  -webkit-box-shadow:0 -1px 7px -1px rgba(87, 97, 100, 0.35);
  -moz-box-shadow:0 -1px 7px -1px rgba(87, 97, 100, 0.35);
  box-shadow:0 -1px 7px -1px rgba(87, 97, 100, 0.35);

}
#header, #navigation.affix{
  top: 0;
  z-index:9999; left:0; right:0;
  width: 100%;
}
/* ------------ white header ------------ */
/* ------------ navbar-default ------------ */
.navbar-default {
  background-color:transparent;
  border-color: transparent;
}
.navbar , .navbar-default{
  border:none;
}
.navbar {
  margin-bottom: 0;
  min-height: auto;
}
.navbar-collapse {
  overflow-x: visible !important;
}
.navbar-collapse.in {
  overflow-y: auto !important;
  height: auto !important;
}
.navbar-brand {
  height: auto;
  padding: 0;
  float: none;
  display: block;
  width: auto;
  margin: 8px 0 6px 0;
}
#navigation.affix .navbar-brand {
  padding: 0;
  width: 190px;
}
.navbar-brand > img {
  display: block;
  max-width: 190px;
}
.navbar-default .navbar-nav > li{
  margin:0 0 0 35px;
}

.navbar-default .navbar-nav > li > a {
  color: #888888;
  font-size:12px;
  font-weight:600;
  letter-spacing:1px;
  text-transform:uppercase;
  padding:23px 0;
}

.navbar-default .navbar-nav > li > a:hover,
#navigation.affix .navbar-default .navbar-nav > li > a:hover{
  color:$primary;
  background-color:transparent;
}
.navbar-default .navbar-nav > .active a, .navbar-default .navbar-nav > .active a:hover,
.navbar-default .navbar-nav > .active a:focus,
#navigation.affix .navbar-default .navbar-nav > .active a, #navigation.affix .navbar-default .navbar-nav > .active a:hover,
#navigation.affix .navbar-default .navbar-nav > .active a:focus{
  background-color: rgba(0, 0, 0, 0);
    color: $primary;
}
.navbar-default .navbar-nav > li > a:before {
    content: "";
    background-color: $primary;
    width: 0%;
    height: 1px;
    position: absolute;
    bottom: 0;
    z-index: 1111;
}
.navbar-default .navbar-nav > li:hover > a:before { width:100%;transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;}
.navbar-default .navbar-nav > li.active > a:before {
    content: "";
    background-color: $primary;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    z-index: 1111;
}

.navbar-toggle {
  background: transparent !important;
  border: medium none;
  margin-right: 0;
}

.navbar-toggle:hover {
  background: transparent !important;
}
.navbar-toggle .icon-bar {
  width: 22px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  background-color:$white;
}
.navbar-toggle.collapsed .bottom-bar {
    transform: rotate(0deg);
}
#navigation.affix .navbar-toggle .icon-bar{
  background-color:$primary !important;
}
.navbar-toggle .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggle .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
  margin: auto;
  width: 12px;
}
.navbar-toggle .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
.navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggle.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* ------------ navbar-default ------------ */
/* ------------- Push Menu ------------ */
#main-navigation.noborder {
  box-shadow: 0 0;
}
.main-button {
  height: auto;
  position: fixed;
  width:auto;
  z-index: 99999;
  top: 31px;
}
.main-button.right{
  right: 15px;
}
.main-button.left{
  left: 15px;
}
.main-button.left > button.menu-active{
  left:250px;
}
.main-button > button {
  background-color:$primary;
  border:none;
  padding:8px;

}
.main-button > button:hover span:first-child,
.main-button > button.menu-active span:first-child{
  transform: translateY(7px) rotate(-45deg);
}

.main-button > button:hover span:nth-child(2),
.main-button > button.menu-active span:nth-child(2){
  opacity: 0;
  transform: rotate(-45deg);
}
.main-button > button:hover span:last-child,
.main-button > button.menu-active span:last-child{
  transform: translateY(-7px) rotate(-135deg);
}
.main-button > button span {
  background: $white none repeat scroll 0 0;
  display: block;
  height: 2px;
  pointer-events: none;
  transform-style: flat !important;
  width:20px;
}.main-button > button span:nth-child(2){
  margin: 5px auto;width: 12px;
}
.navbar-brand {
  display: inline-block;
}
.cbp-spmenu {
  background:$white;
  position: fixed;
  box-shadow:0px 0px 8px 1px rgba(0,0,0,0.176);
}
.push_nav_brand{
  margin:30px 0 30px 15px;
  display: inline-block;
  width:110px;
}
.logo-space{ padding:32px 0;}
.push_nav li{
  position:relative;
  overflow: hidden;
}
.push_nav li a{
border-bottom: 2px solid #f5f5f5;
    color: #888888;
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    position: relative;
    text-transform: uppercase;
}
.push_nav li a:hover, .push_nav li.active a, .push_nav .active a:hover, .push_nav li.active a:focus{
  color:$primary;
}
/* ------------- Push Menu ------------ */



/* ------------ Main Banner ------------ */
#main-slider { font-family:"Lato",sans-serif; }
/*Light-Slider*/
#main-slider.light-slider{color:$white;}
#main-slider.light-slider .tp-banner p{color: #f5f5f5;}

/*Dark-Slider*/
#main-slider.dark-slider{color:$white;}
#main-slider.dark-slider .tp-banner p{color: $white; font-weight:300;}
#main-slider.dark-slider .hvr-outline-out{color:$white;}
#main-slider.dark-slider .hvr-shutter-out-vertical{background-color: $primary; color:$white;}
#main-slider.dark-slider .hvr-shutter-out-vertical:hover{color:#000;}
#main-slider.dark-slider .hvr-shutter-out-vertical:hover:before {color:#000;background-color: $white;}
#main-slider.dark-slider .hvr-outline-out::before{border-color: white; color:$white}
#main-slider.dark-slider .hvr-outline-out:hover::before {border-color: white;}
.tp-banner h2{font-size:36px; font-weight:300;}
.tp-banner p{font-size:18px; font-weight:normal;}
#main-slider h2.tp-caption > span{display:block;}
#main-slider .tp-caption a{margin:5px;}
.tp-bullets{ opacity:1;}
.tp-bullets .bullet{background-color: rgba(143, 143, 143, 0.7); transition:all 0.3s ease-out 0s; height:3px !important;}
.header-crystal .tp-bullets .bullet{background-color:rgb(255, 255, 255); transition:all 0.3s ease-out 0s;}
.tp-bullets .bullet.selected, .tp-bullets .bullet:hover{border: 1px solid $primary; background-color:$primary;height:3px !important;}
.tp-caption{ padding:0 15px !important;}

.layer-content p{color:#000;font-weight:400;}
.layer-content h2{color:#000;font-weight:600;}
.layer-content h2 span{color:#07AAA5;font-weight:600;display:inline-block!important;}
.layer-content h2 span.green-text{color:$green;}
.default.round.hidearrows {opacity: 0;}

#slider2 .tp-leftarrow, #slider2 .tp-rightarrow{background-color: $white; color:#444;}
.tp-leftarrow:hover, .tp-rightarrow:hover, #slider2 .tp-leftarrow:hover, #slider2 .tp-rightarrow:hover {background:$primary; color:$white;}
.tp-leftarrow:hover {padding-right: 12px;}
.tp-rightarrow:hover{padding-left:12px;}
#slider2 .tp-leftarrow:hover{padding-right: 12px;}
#slider2 .tp-rightarrow:hover{padding-left: 12px;}
/* ------------ Main Banner ------------ */

/*-------------------Video Bg-------------------*/


.homepage-hero-module {
    border-right: none;
    border-left: none;
    position: relative;
}
.no-video .video-container video,
.touch .video-container video {
    display: none;
}
.no-video .video-container .poster,
.touch .video-container .poster {
    display: block !important;
}
.video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;
}
.video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
}
.video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
}
.video-container video {
    position: absolute;
    z-index: 0;
    bottom: 0;
}
.video-container video.fillWidth {
     width: 100%;
}
.video-overlay {
    background-color: #000000;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}
.video-block {
    color: $white;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 40%;
    z-index: 11111;
}
.vd-sm-caption {
    color: $white;
    font-size: 24px;
    font-weight: 300;
}
.vd-md-caption.text-center {
    font-size: 54px;
    font-weight: 200;
    line-height: 60px;
}
.video-block .btn {
    color: $white;
    margin: 50px 10px;
}
.video-block .btn.hvr-shutter-out-vertical{background-color: $white; color: #000;}
.video-block .btn.hvr-shutter-out-vertical:hover{ color: $white;}

/*-------------------Video Bg-------------------*/
/* ------------ Banner With Text Rotator ------------ */
.text-rotator{
  background:url('../images/accueil/picture-007.jpg');
  padding-top:200px;
  background-size:cover;
  background-attachment:fixed;
  background-position:center center;
  width:100%;
}
.text-rotator #paralax-slider{
  padding:15% 0;
}
#paralax-slider .item-content p{
  font-size:20px;
}
#paralax-slider .item-content p ,#paralax-slider .item-content h2{
    color:$white;
}
#paralax-slider .item-content h2{
  font-size:58px;
  margin-bottom:25px;
  font-weight:100;
}

#paralax-slider .owl-controls {
  margin-top:5%;
}
#paralax-slider .owl-controls .owl-page span{
  background: $white;
  text-align: center;
  height:12px;
  width:12px;
  border-radius:50%;
  opacity: 1;
}
#paralax-slider .owl-controls .owl-page span:hover,
#paralax-slider .owl-controls .active span{
    background:$primary;
}


/*--moduleControl--*/
#moduleControl {
    position: fixed;
    right: 20px;
    top: 35%; z-index:9999;
}
#moduleControl li{display: block; margin-bottom:10px;}
#moduleControl li a{background-color: #dedede;display: block;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0;
    height: 1px;
    margin-bottom: 10px;
    width: 10px;  outline: 0 none;
    text-indent: -9999px;
    transition: all 0.3s ease-out 0s;}
#moduleControl li:hover a, #moduleControl li.active a{
    background-color: $primary;
    border: 1px solid $primary;
    color: #000000;
}

/* ------------ About Us ------------ */
.canvas-box{
  cursor: pointer;
}
.desk-img{margin:auto auto 30px;display: block;;}
.canvas-box span{
  display: inline-block;
  margin-bottom:15px;
  padding:5px;
}
.canvas-box span i{
  display: inline-block;
  font-size:35px;
}
.color-style1{ color: $grey;}
.color-style2{ color: $primary;}
.color-style3{ color: $green;}
.color-style4{ color: $purple;}
.color-style5{ color: $orange;}
.color-style6{ color: $indigo;}
.color-style7{ color: $blue;}

.canvas-box:hover span i,
.counters-item:hover i,
.we-do .do-wrap:hover i{
  -moz-transform:scale(1.3);
  -ms-transform:scale(1.3);
  -o-transform:scale(1.3);
  -webkit-transform:scale(1.3);
  transform:scale(1.3);
}

.canvas-box:hover h4.color-style1{ color: $grey;}
.canvas-box:hover h4.color-style2{ color: $primary;}
.canvas-box:hover h4.color-style3{ color: $green;}
.canvas-box:hover h4.color-style4{ color: $purple;}
.canvas-box:hover h4.color-style5{ color: $orange;}
.canvas-box:hover h4.color-style6{ color: $indigo;}
.canvas-box:hover h4.color-style7{ color: $blue;}
.canvas-box h4{
  margin-bottom:15px;
  color:#444;font-weight: normal;
}

/* ------------ Paralax background ------------ */
#bg-paralax{
    left: 0;
    background:url('../images/paralax-index1.jpg');
    position: absolute;
    right: 0;
    top: 0;
}
#bg-paralax {
  color: $white;
  padding:8% 0; position:relative; overflow:hidden;
}
#bg-paralax , #testinomial{
  background-size:cover;
  background-position:center center;
  background-attachment:fixed;
  width:100%;
}
#bg-paralax .col-md-12{z-index:9999;}
#bg-paralax::after, #bg-paralax::before {
    background-color: rgb(255, 0, 0);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
}
#bg-paralax , #testinomial{
  background-size:cover;
  background-position:center center;
  background-attachment:fixed;
  width:100%;
}

#bg-paralax p{
  margin-bottom:25px;
  color: $white;
}
#bg-paralax h2{
  font-size: 30px;
    font-weight: normal;
}
/* ------------ Paralax background ------------ */

/* ------------ Work Process------------ */
.title-type-2 {
    position: relative;
    margin-bottom: 20px;
}
.title-type-2:before{ position:absolute; left:0; width:60px; height:2px; content:""; background-color:#FF0006;bottom: 0;}
.title-type-2 .head-title {
    margin-bottom: 5px;
}
.title-type-2 .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
}


#full-img{ position:relative;}
.img-full-img{ margin-top: -25px; margin-left: -15px;}
.btn-more{position:relative; margin:auto;}
a.more::before {
    background: $primary none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: -83px;
    position: absolute;
    top: 20px;
    transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    width: 115px;
}
 /* ------------ Work Process------------ */
  /*
 * Progress bars
 */

#skills-bar { padding:50px 0 0;
}
#skills-bar p{ line-height:20px;}
.progress-box {
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  overflow: visible;
  height: 4px;
  background: #ccc;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-box .progress-bar {
  padding-top: 3px;
  position: relative;
  overflow: visible;
  background-color: $primary;
  font-size: 11px;
  color: #666666;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 30%;
}
.progress-box .progress-bar > span {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 0 3px;
  position: absolute;
  top: 0px;
  right: -5px;
  text-align: center;
  line-height: 23px;
  letter-spacing: 0;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
  /*
 * Progress bars
 */
 /*  ------------ Counters Fact Info  ------------ */
#facts .counters-item{
  font-weight:bold;
  vertical-align:middle;
  color:#a1a1a1;border-right: 1px solid rgba(161, 161, 161, .4);
  cursor:pointer;
}

#facts .col-md-3.col-sm-3:nth-child(4) .counters-item {
    border: 0;
}
#facts .counters-item h2{
  color:#a1a1a1;margin: 20px 0 10px;
}
#facts .counters-item h2 strong {
    color: #5d5d5d;
    font-weight: 300;
    margin: 25px 0 10px;
}

.counters-item i {
  font-size:30px;
  display: block;
  margin-bottom:15px;color: #5d5d5d;
}
.counters-item p{
  color:#a1a1a1;
  text-transform: capitalize;
  font-weight:bold;
}

/*  ------------ Counters Fact Info  ------------ */



/* ------------  Our Team ------------  */
#our-team .Portfolio-wrap img, #blog-slider .item .image img{
  width:100%;
  -moz-transition:all .2s linear;
  -ms-transition:all .2s linear;
  -o-transition:all .2s linear;
  -webkit-transition:all .2s linear;
  transition:all .2s linear;
}
#blog-slider .item {
    margin: 0 15px;
}
.team-wrap{padding:10px 10px 50px; background-color: $white;}
.team-wrap p{
  margin:0 10px;
}
.team-image{
  width:100%;
  overflow:hidden;
  position:relative;
}

.team-image .overlay{
  background:rgba(255, 7, 42, 1);
  width:100%; /* 101% */
  bottom:0;
  top: auto;
  /*opacity:0;
  filter: alpha(opacity=0);position:absolute;*/
  left:0;
  right:0;
  cursor:pointer;
  padding:0;
  height:80px;
  position:inherit;
  opacity:1;
  z-index: 1111;
}


.team-image .overlay ul.social-link li a{
  border: 1px solid $white;
}
.team-image .overlay ul.social-link li a > i{
  color: $white;
}
.team-image .overlay ul.social-link li a:hover > i{
  color: #121416;
}
.team-image .overlay ul.social-link li a:hover span{
  background: $white;
  border:1px solid $white;
}
.team-wrap:hover .team-image img,  #blog-slider .item:hover .image img
{
  transform:scale(1.3);
  -webkit-transition: all 0.3s ease-in-out ;
    -moz-transition:all 0.3s ease-in-out ;
  -ms-transition:all 0.3s ease-in-out ;
    -o-transition:all 0.3s ease-in-out ;
  transition:all 0.3s ease-in-out;
}
#our-team .team-wrap h3{
  margin-top:20px;
  font-size:20px;font-weight: 500;
}
#our-team .team-wrap small{
  display: inline-block;
  margin: 5px 0 15px;
  font-size: 100%;
}

#our-team .team-wrap ul.social-contact li{
  display:inline-block;
}

#our-team .team-wrap ul.social-contact li a{
  font-weight:bolder;
  color:$grey-2;
  margin:0 8px;
  text-transform:uppercase;
  position:relative;
  padding-bottom:3px;
}
#our-team .team-wrap ul.social-contact li a:hover::before{
  opacity:1;
  filter: alpha(opacity=100);
  transform: translateY(0px);
}
#our-team .team-wrap ul.social-contact li a:hover.facebook{
  color:#3b5998 !important;
}

#our-team .team-wrap ul.social-contact li a:hover.facebook::before{
  background: #3b5998;
}

#our-team .team-wrap ul.social-contact li a:hover.twitter{
  color:#1da1f2;
}

#our-team .team-wrap ul.social-contact li a:hover.twitter::before{
  background: #1da1f2;
}

#our-team .team-wrap ul.social-contact li a:hover.linkden{
  color:#0077B5;
}

#our-team .team-wrap ul.social-contact li a:hover.linkden::before{
  background: #0077B5;
}
/*team Share*/

ul.social-link li a{
  display: inline-block;
}

ul.social-link li{
  margin:19px 1px;
  display:inline-block;
}
.team-wrap ul.social-link li a {
    border-radius: 0;
}
ul.social-link li a {
  border: 1px solid #000;
  border-radius: 40px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  position:relative;
  color: #000 !important;
}
ul.social-link li a:hover {
  border: 1px solid $primary;
  color: $white;
}

ul.social-link li a span{
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 0;
}
.team-wrap ul.social-link li a:hover span{ border-radius:0;}
ul.social-link li a:hover span {
  background: $primary;
  border-radius:40px;
  height:40px;
  width:40px;
  border: 1px solid $primary;
  top: -1px;
  left: -1px;
  right: 0;
  bottom: 0;
}
ul.social-link li a i {
  height: 100%;
  left: 0;
  line-height: 42px;
  position: absolute;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 10;
}

ul.social-link li a :hover,  ul.social-link li a:focus{
  color:$white;
  border: none;
}
/* ------------  Our Team ------------  */

/* ------------  Portfolio Work ------------  */

.work-filter {
  margin-bottom:50px;
}

.work-filter ul li {
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.work-filter ul li:before {
    content: "|";
    position: absolute;
    right: -20px;
    top: 0;
}
.work-filter ul li a {
  color: #a1a1a1;
  display: inline;
  font-size: 16px;
  padding: 6px 10px;
  text-transform: capitalize;
  position:relative;
}
.work-filter ul li a:before,
.work-filter ul li a.active:before {
    background-color: $primary;
    bottom: 0;
    content: "";
    height: 1px;
    position: absolute;
    width: 0;
    z-index: 1111;left: 0;right: 0;

}
.work-filter ul li a:hover:before,
.work-filter ul li a.active:hover:before {
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    width: 100%;
}

.work-filter ul li a.active {
  color: #a1a1a1;
}
.work-filter ul li a.active:before{ width:100%; height:1px; position:absolute; left:0; right:0; bottom:0; content:"";background-color: $primary;}

.mix {
    display: none;
}

.index_2 .work-item {
    width:20%;
}

.work-item {
  height:auto;
  float:left;
  position: relative;
  overflow:hidden;padding: 15px;
}

.work-item > img {
  display: block;
  height: auto;
  max-width: 100%;
  width:100%;
}
.item-containe > img{
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition:all 0.7s ease 0s;
  -ms-transition:all 0.7s ease 0s;
  -o-transition:all 0.7s ease 0s;
  transition:all 0.7s ease 0s;
}
.item-container:hover img{
  transform:scale(1.2);
  -webkit-transition: all 0.3s ease-in-out ;
  -moz-transition:all 0.3s ease-in-out ;
  -ms-transition:all 0.3s ease-in-out ;
  -o-transition:all 0.3s ease-in-out ;
  transition:all 0.3s ease-in-out;
}

.overlay {
  background-color:$primary;
  position: absolute;
  left:10px;
  top:10px;
  bottom:10px;
  right:10px;
  width:auto;
  height:inherit;
  color: $white;
  opacity: 0;
  filter: alpha(opacity=0);
  padding:2%;
  z-index:1;
}

.overlay-inner{
  margin: auto;
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.item-container:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}
.work-item:hover .line{
  width:40%;
}
.overlay h4.color{ color:$white;}
.overlay h4.base{ color:$white;}

.work-item .overlay p{
  font-size:14px; color:$white;
}
.overlay .line{
  width:0%;
}
.overlay .line ,
.product-content .line{
  height:1px;
  margin:15px auto;
  background-color:$white;
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}
.work-item .overlay a.portfolio-btn{ background-color: $white;
    border-radius: 100px;
    color: #444;
    display: inline-block;
    font-weight: bold;
    height: 40px;
    line-height: 41px;
    opacity: 1;
    width: 40px;
    margin: 0 2px;}
.work-item .overlay i {
    font-size: 20px;
}

/* Portfolio Details */
.project-title{  font-size: 20px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: normal;
    margin: 0 0 20px;
    padding-bottom: 10px;
    position: relative;
    text-transform: capitalize;
    color:#444;
}
.project-title::before{
  width:50px;
  height:2px;
  position:absolute;
  left:0;
  bottom:0;
  content:'';
  background:$primary;
}
.project-thumb{
  width:100%;
  float:left;
  height:auto;
}
.project-thumb img{
  width:100%;
  height:auto;
}
.project-desc{
  width:100%;
  float:left;
  height:auto;
}
.project-desc .fa{
  color:$primary;
}
.project-desc ul li + li{
  margin-top:8px;
}
.related-project{
  width:100%;
  float:left;
  height:auto;
  margin:40px 0 0;
}



/* ------------  Portfolio Work ------------  */

/*  ------------ Pricing Tables  ------------ */

#pricing-table{overflow: hidden;}

.pricing .thumbnail {
    background: $white;
    border-radius: 100%;
    display: block;
    font-size: 36px;
    height: 90px;
    line-height: 90px;
    margin: 0 auto;
    text-align: center;
    width: 90px;
}

.pricing .thumbnail {
    font-size: 30px;
    line-height: 90px;
    text-align: center; color:#444; border:0;
}
.pricing {
  width: 90%;
  max-width: 1170px;
  margin: 4em auto;
}

.pricing {
    margin: 0 auto;
}
.pricing.full-width {
    width: 100%;
    max-width: none;
}

.pricing-switcher {
  text-align: center;
}

.pricing-switcher .fieldset {
    background-color: $primary;
    border-radius: 50em;
    color: $grey;
    display: inline-block;
    position: relative;
}

.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}


.pricing-switcher label {
  color: $white;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 12px;
  height: 30px;
  letter-spacing: 1px;
  line-height: 30px;
  margin: 0;
  position: relative;
  width: 80px;
  z-index: 1;
}

.pricing-switcher .switch {
  position: absolute;
  top: 0;
  left:0;
  height: 30px;
  width: 80px;
  background-color: $grey-4;
  border-radius: 50em;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}

.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(90px);
  -moz-transform: translateX(90px);
  -ms-transform: translateX(90px);
  -o-transform: translateX(90px);
  transform: translateX(90px);
}

.no-js .pricing-switcher {
  display: none;
}

.pricing-list {
  margin: 2em 0 0;
  list-style-type: none;
  padding-inline-start: 0;
}

.pricing-list > li {
  position: relative;
  margin-bottom: 1em;
}

.pricing .pricing-features li .fa-check {
    color: #2ecc71;
}
.pricing .pricing-features li .fa {
    margin-right: 10px;
    text-align: center;
    width: 16px;
}
.pricing .pricing-features li .fa-close {
    color: #e74c3c;
}
.pricing-list .dl-horizontal.dl-large dt {
  width: 230px;
}
.pricing-list .dl-horizontal.dl-large dd {
  margin-left: 250px;
  max-width: 46px;
}
.dl-horizontal dt.text-left {
  text-align: left;
}
.horaires {
  width: 76px;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .pricing-list {
    margin: 3em 0 0;
  }
  .pricing-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .has-margins .pricing-list > li {
    margin-right: 1.5%;
  }
  .has-margins .pricing-list > li:last-of-type {
    margin-right: 0;
  }
}

.pricing-wrapper {
  position: relative;
  list-style-type: none;
  padding-inline-start: 0;
}

.touch .pricing-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}

.pricing-wrapper.is-switched .is-visible {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
}

.pricing-wrapper.is-switched .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}

.pricing-wrapper.is-switched.reverse-animation .is-visible {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  -moz-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}

.pricing-wrapper.is-switched.reverse-animation .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  -moz-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}

.pricing-wrapper > li {
  background-color: $white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
}

.pricing-wrapper > li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  pointer-events: none;
}

.pricing-wrapper > li.is-ended::after {
  display: none;
}

.pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}

.pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.pricing-wrapper .is-selected {
  z-index: 3 !important;
}

@media only screen and (min-width: 768px) {
  .pricing-wrapper > li::before {
    content: '';
    position: absolute;
    z-index: 6;
    left: -1px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
    background-color: #b1d6e8;
  }
  .pricing-wrapper > li::after {
    display: none;
  }
  .pricing .exclusive .thumbnail {
    border: 0 none;
    color: $primary;
    font-size: 30px;
    line-height: 82px;
    text-align: center;
}
.pricing .exclusive .thumbnail {
    background: $white none repeat scroll 0 0;
    border-radius: 100%;
    display: block;
    font-size: 36px;
    height: 90px;
    line-height: 82px;
    margin: 0 auto;
    text-align: center;
    width: 90px;
}
  .has-margins .pricing-wrapper > li,
  .has-margins .exclusive .pricing-wrapper > li {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  :nth-of-type(1) > .pricing-wrapper > li::before {
    display: none;
  }
  .has-margins .pricing-wrapper > li {
    border-radius: 4px 4px 6px 6px;
  }
  .has-margins .pricing-wrapper > li::before {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .full-width .pricing-wrapper > li {
    padding: 2.5em 0;
  }
}

.no-js .pricing-wrapper .is-hidden {
  position: relative;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  margin-top: 1em;
}

@media only screen and (min-width: 768px) {
  .exclusive .pricing-wrapper > li::before {
    display: none;
  }
  .exclusive + li .pricing-wrapper > li::before {
    display: none;
  }
}

.pricing-header h2 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    /* text-transform: capitalize; */
    background-color: $grey-4;
    color: $white;
}

.pricing-header {
    height: auto;
    padding: 1.9em 0 0;
    pointer-events: auto;
    text-align: center;
    color: #173d50;
    background-color: rgba(164, 164, 164, .5);
}

.exclusive .pricing-header {
    color: #1bbc9d;
    background-color: #ff072a;
}

.currency,
.value {
  font-size: 3rem;
  font-weight: 300;
}
.price{background-color: $white;}
.duration {
  font-weight: 700;
  font-size: 1.3rem;
  color: #8dc8e4;
  text-transform: uppercase;
}

.exclusive .duration {
  color: #f3b6ab;
}

.duration::before {
  content: '/';
  margin-right: 2px;
}

.value {
  font-size: 3rem;
  font-weight: 300;
  color:$primary;
}

.currency,
.duration {
    color:$grey-4;
}

.exclusive .currency,
.exclusive .duration {
    color: #444;
}

.currency {
    font-size: 2.4rem;
}

.duration {
    font-size: 1.4rem;
}

.pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.is-switched .pricing-body {
  overflow: hidden;
}

.pricing-body {
  overflow-x: visible;
  font-size: 16px;
}

.pricing-features {
  width: auto;
  padding-inline-start: 0;
}

.pricing-features:after {
  content: "";
  display: table;
  clear: both;
}

.pricing-features li {

  float: none;
  padding: 1.6em 1em;
  font-size: 15px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pricing-features em {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.pricing-features li {
    width: auto;
    padding: .9em 1em;color: #666;
}
.pricing-features li:nth-child(2n+1) {
    background-color: #eeeeee;
}
.exclusive .pricing-features li {
    margin: 0 3px;
}

.pricing-features em {
    display: inline-block;
    margin-bottom: 0;
}

.has-margins .exclusive .pricing-features li {
    margin: 0;
}

.pricing-footer {
  z-index: 1;
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  background-color: #D1D1D1;
}
.exclusive .pricing-footer{background-color: $primary;}
.pricing-footer::after {
    display: none;
}

.has-margins .pricing-footer {
    padding-bottom: 0;
}

.select {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}

.select {
  background-color: $white;
    color: #a1a1a1;
    display: inline-block;
    height: auto;
    position: static;
    text-indent: 0;
    text-transform: capitalize;
    transition: all 0.6s ease 0s;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
}
.select.hvr-sweep-to-right::before{background-color: $grey-3;}
.no-touch .select:hover {
  color: $white;
}

.exclusive .select {
    background-color: $white;
  color: #444;
}

.no-touch .exclusive .select:hover {
    background-color: #444; color:$white;
}

.secondary-theme .exclusive .select {
    background-color: #1bbc9d;
}

.no-touch .secondary-theme .exclusive .select:hover {
    background-color: $white; color:$white;
}

.has-margins .select {
    display: block;
    padding: 1.7em 0;
    border-radius: 0 0 4px 4px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
}

@-moz-keyframes rotate {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}

@-webkit-keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}

@-moz-keyframes rotate-inverse {
  0% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}

@keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-webkit-keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
}

@-moz-keyframes rotate-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
}

@keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@-webkit-keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}

@-moz-keyframes rotate-inverse-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}

@keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}
/*  ------------ Pricing Tables  ------------ */



/*  ------------ skill Bar  ------------ */
.skill-tab{margin:50px 0 0;}
.tooltip{
  position:relative;
  float:right;
}
.tooltip > .tooltip-inner {background-color: $grey-3;
    color: $white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 0;
    opacity: 1;}
.popOver + .tooltip > .tooltip-arrow {  border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid $grey-3;}

.progress{
  border-radius:0;
  overflow:visible;
}
.progress-bar{
  background:$primary;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out; box-shadow:0 0 0;
}
.progressText {
    margin-bottom: 10px;
    display: block;
}
.progress .tooltip.top{ margin-top: 12px;
    padding: 5px 0;}
/*  ------------ skill Bar  ------------ */
/*  ------------ tab  ------------ */
.tab-content > .tab-pane {
  padding-top: 15px;
}
.nav-tabs > li > a {
    border: 0 none;
    border-radius: 0;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: 1px;
    line-height: 1.42857;
    margin-right: 2px;background-color: $grey-3;color: $white;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  background-color: $primary;
  border: 0!important;
  color: $white;
}
.nav > li > a:focus, .nav > li > a:hover {
  background-color: $grey-3;
  border: 0 none;
  text-decoration: none;
  color: white;
}
/*  ------------ tab  ------------ */
/* ------------ Testinomials ------------ */

#testinomial{
  background:#F8F8F8;

}

#testinomial-slider .item{
  display: block;
  width: 100%;
  height: auto;
  color:$grey-4;
}

#testinomial-slider .item p {
    font-size: 17px;
    margin-left: 13%;
    margin-right: 13%;
    line-height: 26px;
}
#testinomial-slider .clinet-img {
    background-color: #D1D1D1;
    border-radius: 100px;
    height: 60px;
    margin: auto;
    overflow: hidden;
    width: 60px;
}
#testinomial-slider .item h5 {
    color: $grey-4;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 10px;
    font-weight: on;
}

#testinomial-slider .owl-prev,
#testinomial-slider .owl-next{
  border:1px solid $white;
  color:$white;
}

#testinomial-slider .owl-prev,
#testinomial-slider .owl-next ,
#blog-slider .owl-prev ,
#blog-slider .owl-next {
  background: $primary;
    font-size: 25px;
    height: 38px;
    line-height: 26px;
    opacity: 1;
    position: absolute;
    top: 40%;
    width: 38px;
    border: 0;
}

#testinomial-slider .owl-prev:hover,
#testinomial-slider .owl-next:hover ,
#blog-slider .owl-prev:hover ,
#blog-slider .owl-next:hover{
  background-color:$grey-3;
  color:$white;
  -webkit-transition: background 0.3s linear 0.1s;
  -ms-transition: background 0.3s linear 0.1s;
  transition: background 0.3s linear 0.1s;
}
#testinomial-slider .owl-prev{
  left:0;
}
#testinomial-slider .owl-next{
  right:0;
}
/* ------------ Testinomials ------------ */

/* ------------ Our Client ------------ */
#client-slider .owl-prev, #client-slider .owl-next{
  background: $grey;
  color: $white;
  opacity: 1;
  display: inline-block;
  font-size: 20px;
  margin: 5px;
  padding: 2px 12px;
  -webkit-transition: all 0.5s ease;/* Safari 3.2+, Chrome */
  -moz-transition: all 0.5s ease;/* Firefox 4-15 */
  -o-transition: all 0.5s ease;/* Opera 10.5-12.00 */
  transition: all 0.5s ease;/* Firefox 16+, Opera 12.50+ */
}
#client-slider .owl-prev:hover, #client-slider .owl-next:hover{background-color: $primary;}
/* ------------ Our Client ------------ */
/* ------------  blog ------------ */
#blog-slider .item{
  margin:0 15px;
}
#blog-slider .item:hover{
  cursor:pointer;
}
#blog-slider .item .image{
  overflow: hidden;
  position: relative;
  width: 100%;
}

#blog-slider .item > img{
  display: block;
  width: 100%;
  height: auto;
}
#blog-slider .item h5{
  font-size:14px;
  color:#727272;
  margin-top:25px;
}
#blog-slider .item h5 , #blog-slider .item h4{
  margin-bottom:10px;
}
#blog-slider .item h4{
  color:#444;
}
#blog-slider .item a.name{
  color:$grey-4;
}

#blog-slider .item a.name ,
#blog-slider .item a.comment{
  font-size:15px !important;
  margin-bottom:10px;
  display:inline-block;
  text-transform:none;
}
#blog-slider .item a.comment{
  color:$primary;
}
#blog-slider .item a.comment:before{
  content:'';
  background:transparent;
}
#blog-slider .item p > a{
  font-size:15px;
}
#blog-slider .item > a{
  color: $grey-4;
  font-size: 15px;
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
  letter-spacing: 1px;
}
#blog-slider .item > a:hover{
  color:$primary;
}
#blog-slider .owl-prev{
  left:-5%;
}
#blog-slider .owl-next{
  right:-5%;
}

/************************ Single Page *********************************/
.main-content {
    float: left;
    height: auto;
    padding: 60px 0;
    width: 100%;
}
.content-block,
.single_post{
  width:100%;
  float:left;
  height:auto;
}
#page-title{
  padding:60px 0;
  position:relative;
  min-height:310px;
}
.title-info{
  width:100%;
  float:left;
  height:auto;
  position:absolute;
  top:60%;
  left:0;
  -webkit-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  transform:translateY(-50%);
}
#page-title::before{
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  content:'';
  background:rgba(0,0,0,0.5);
}
#page-title h1{
  margin:0; font-size:30px;
  color:$white;
}
.post_thumb{
  width:100%;
  float:left;
  height:auto;
  margin:0 0 13px;
}
.post_thumb > img{
  width:100%;
  float:left;
}
.meta{
  width:100%;
  float:left;
  height:auto;
  padding:0 0 6px 0px;
  border-bottom:1px solid #dce4ea;
  margin:0 0 25px;
}
.meta > span{
  font-size:14px;
  color:#777;
  font-weight:400;
  line-height:20px;
  display:inline-block;
  padding-right:20px;
  margin-right:10px;
  border-right:1px solid #dce4ea;
}
.meta > span a{
  font-weight:400;
  font-size:13px;
  color:#777;
  text-decoration:none;
}
.meta > span a:hover{
  color:$primary;
}
.meta > span.date{
  border:none;
  padding:0;
  margin:0;
}
.single_post h2{
  font-weight:normal;
  color:#444;
  margin:0 0 15px;
  width:100%;
  float:left;
  height:auto;
}

.post_desc{
  width:100%;
  float:left;
  height:auto;
  margin:0 0 20px;
}
blockquote{
      background: #f4f4f4 none repeat scroll 0 0;
    border-color: $primary;
    font-family: sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    line-height: 21px;
}
.post_bottom{
  width:100%;
  float:left;
  height:auto;
  margin:0 0 35px;
}
.post_bottom ul{
  width:100%;
  float:left;
}
.post_bottom ul li{
     display: block;
    float: left;
    margin-right: 17px;
    margin-top: 0;
    width: auto;
}
.post_bottom ul li a{
  position:relative;
  text-decoration:none;
  width:59px;
  height:39px;
  background:#F8F8F8;
  -webkit-border-radius:20px;
  -moz-border-radius:20px;
  border-radius:20px;
  display:block;
  text-align:center;
}
.post_bottom ul li:hover a, .post_bottom ul li:hover a i{ background-color:$primary; color:$white;}
.post_bottom ul li a span{
  font-weight:600;
  font-size:10px;
  text-decoration:none;
  color:$white;
  line-height:18px;
  background:$primary;
  border:3px solid $white;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  position:absolute;
  top:-8px;
  width:24px;
  text-align:center;
  display:block;
  right:-6px;
}
.post_bottom .fa{
  line-height:39px;
  font-size:18px;
  color:#777;
}
.custom-title{
  width:100%;
  float:left;
  height:auto;
}
.custom-title h2 {
  border-bottom: 2px solid $primary;
  font-size: 20px;
  margin: 0 0 20px;
  padding: 13px 18px 13px 0;
  /* text-transform: capitalize; */
  font-weight:normal;
}
.custom-title h2:before{
  background:none;
}
.comment-list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width:100%;
  float:left;
}
.comment li {
  border-right: 2px solid #ccc;
  padding-right: 10px;
}
.comment li:last-child {
  border-right: 0 none;
}
.comment-info {
    display: block;
    margin: 30px 0;
    overflow: hidden;
}
.comment-info p {
  margin-bottom: 0px;
}
.comment-info img {
  max-width: 70px;
  border:1px solid $primary;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
}
.comment-info .author-desc {
  margin-left: 80px;
  padding:15px;
  background:#fafafa;
}
.comment-info .author-title {
  line-height: 16px;
  margin-bottom: 12px;
  color:#777;
}
.author-title strong {
  font-size: 15px;
  color:#777;
  font-weight:500;
  text-transform:capitalize;
}
.comment-list .list-inline{
  margin-top:0px;
  float: right;
    width: auto;
}
.comment-list .list-inline a{
  color:#777;
  font-weight:400;
  font-size:14px;
}
.comment-list .list-inline a:hover{
  color:$primary;
}
.content-block .form-control{ border:0; border-radius:0; border-bottom:1px solid #e7e4e4;
}
.comment-form{
  width:100%;
  float:left;
  height:auto;
}
/*********************** Sidebar ******************************/
.sidebar{
  width:100%;
  float:left;
  height:auto;
}
.sidebar-item{
  width:100%;
  float:left;
  height:auto;
  margin:0 0 30px;
}
.sidebar-item h3{
  border-bottom: 1px solid $primary;
    color: $grey-4;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0 0 15px;
    padding: 0 0 7px;
}
.sidebar-item .form-control{
  border:1px solid #ccc;
  height:40px;
  color:#777;
}
.sidebar-item .form-control:focus{
  border-color:$primary;
}
.sidebar-item .input-group-addon{
  padding:0;
  border-color:$primary;
  background:$primary;
}
.search-submit {
    border: medium none;
    color: $white;
    font-size: 13px;
    height: 38px;
    padding: 0 8px;
    text-transform: uppercase;
  background:$primary;
}
.category-list,
.archive-list{
  width:100%;
  float:left;
}
.category-list li,
.archive-list li{
  width:100%;
  float:left;
}
.category-list li a,
.archive-list li a{
  display:block;
  padding:10px 0;
  color:#777;
  font-weight:400;
  border-bottom:1px solid #f5f5f5;
}
.category-list li a:hover,
.archive-list li a:hover{
  color:$primary;
  border-color:$primary;
}
.tag-list li{
  width:auto;
  float:left;
  margin:0 10px 10px 0;
}
.tag-list li a {
  border: 1px solid #dce4ea;
    border-radius: 3px;
    color: #888888;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    padding: 6px 9px;
}
.tag-list li a:hover{
  color:$white;
  border-color:$primary;
  background:$primary;
}
.comment-form .form-control::-moz-placeholder,
.comment-form .form-control{ box-shadow:0 0 0; padding:15px 10px; height:auto;
}
.sidebar .form-control{
  padding-left:10px;
}
/* ------------  blog ------------ */


/* ------------ contact ------------ */
#contact .address {
    font-size: 16px;
    color: #989898;
}
#contact .address li {
    margin-bottom: 10px;
}
.share {
    margin: 30px 0 30px;
}
.share a {
    background-color: #cccccc;
    border-radius: 15px;
    color: $white;
    display: inline-block;
    font-size: 16px;
    height: 30px;
    margin-right: 10px;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    width: 30px;line-height: 30px;
}
.share a:hover{background-color: $primary;}
/*form*/
#contact .contact-form {
    background-color: white;
    padding: 50px 15%;
}

#contact .form-control {
    border: 0;
    box-shadow: 0 0 0;
    border-radius: 0;
    border-bottom: 1px solid #e7e4e4;
    padding:15px 10px;
    height: auto;
    font-size: 13px;
    letter-spacing: 1px;
    color: #444;line-height: inherit;
}
#contact .form-group {
    margin: 0;
}
#contact #submit_message {
    margin-top: 18px;
}
#reply_message {
    margin-top: 34px;
    float: right;
}
#contact .form-control:focus {
    border-color: $primary!important;
}


/* ------------ contact ------------ */
/* ------------ Footer ------------ */
#footer{
  background-color: #000;
  padding: 15px 0;
  line-height: 20px;
}
/*footer Share*/
.footer-share a {
  border-radius: 0;
  border-right: 1px solid #cccccc;
  padding: 0 15px;color: #929292;
}
.footer-share {
    margin: 0;
}

.footer-share a {
    border-right: 1px solid #ccc;
    border-radius: 0;
}

.footer-share a:last-child {
    border: 0;
    padding-right: 0;
}
.footer-share a:hover{background-color: transparent;color:$primary;}
.copyright {
    text-transform: uppercase;
    margin: 0 0;
}

.go-top {
  bottom:40px;
  position: fixed;
  font-size:14px;
  right:25px;
  z-index:800;
  background:$grey-4;
  color:$white;
  height:40px;
  width:40px;
  text-align:center;
  line-height:40px;
  opacity:0; border-radius:100px;
}
.go-top:hover, .go-top:focus{
  background:$primary;
  color:$white;
}
.go-top.show {
    opacity: 1;
}
/* ------------ Footer ------------ */


@media only screen and (min-width: 50px) and (max-width: 320px) {
  #main-slider p.tp-caption.tpfirst {
    color: $white;
    font-size: 16px !important;
    font-weight: 400;
    letter-spacing: 1px !important;
    padding: 0 10px !important;
    text-transform: none;
    top: 120px !important;
}
#main-slider h2.tp-caption {
    font-weight: 300;
    text-transform: none;
    top: 165px!important;
    padding: 0 10px!important;
    font-size: 22px!important;
    line-height: 30px!important;
}
#main-slider p.tplast {
    color: $white;
    font-size: 14px !important;
    line-height: 22px !important;
    padding: 0 10px !important;
    top: 250px !important;
    white-space: normal !important;
}

#main-slider li:nth-child(1) p.tplast {
    text-align: right;
}
#main-slider li:nth-child(2) p.tplast {
    text-align: left;
}
#main-slider p.tplast br {
    display: none;
}
.tp-caption.tp-button {
    padding: 0 10px !important;
    top: 350px !important;
}
.tp-caption.tp-button a {
    font-size: 14px !important;
    padding: 15px!important; margin:0 20px 0 0!important
}

.navbar-default .navbar-nav > li {
    margin: 0;
}
.navbar-default .navbar-nav > li > a{padding:12px 0}
.canvas-box.text-left, .canvas-box.text-right {text-align: center;}
.work-filter ul li {
    display: inline-block;
    margin: 0;
}
.img-full-img{margin-left: -15px;}
.work-filter ul li a{padding:6px 8px;}
#blog-slider .owl-prev{left:0;}
#blog-slider .owl-next{right: 0;}

#footer {
    text-align: center;
}
.footer-share {
    float: inherit!important;
    margin: 0 0 10px;
}
.col-xs-6{width:100%;}

.video-block { top: 30%;}
.vd-sm-caption {font-size: 20px;}
.vd-md-caption.text-center {
    font-size: 28px;
    font-weight: 300;
    line-height: 44px;
}
.video-block .btn {
    color: $white;
    float: left;
    font-size: 11px;
    margin: 30px 10px;
    width: 41%;
}
.work-filter ul li::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
}
.work-filter ul li a {
    color: #a1a1a1;
    display: inline;
    font-size: 13px;}
  #skills-bar {
    padding: 0;
}

h2 {
    font-size: 21px;
}
  .img-full-img {
    margin-bottom: 35px;
    margin-left: 0;
    margin-top: -35px;
}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #main-slider p.tp-caption.tpfirst {
    color: $white;
    font-size: 18px !important;
    font-weight: 400;
    letter-spacing: 1px !important;
    padding: 0 10px !important;
    text-transform: none;
    top: 120px !important;
}
#main-slider h2.tp-caption {
    font-weight: 300;
    text-transform: none;
    top: 165px!important;
    padding: 0 10px!important;
    font-size: 21px!important;
    line-height: 30px!important;
}
#main-slider p.tplast {
    color: $white;
    font-size: 15px !important;
    line-height: 22px !important;
    padding: 0 10px !important;
    top: 250px !important;
    white-space: normal !important;
}

#main-slider li:nth-child(1) p.tplast {
    text-align: right;
}
#main-slider li:nth-child(2) p.tplast {
    text-align: left;
}
#main-slider p.tplast br {
    display: none;
}
.tp-caption.tp-button {
    padding: 0 10px !important;
    top: 350px !important;
}
.tp-caption.tp-button a {
    font-size: 14px !important;
    padding: 15px!important; margin:0 20px 0 0!important
}

.navbar-default .navbar-nav > li {
    margin: 0;
}
.navbar-default .navbar-nav > li > a{padding:12px 0}
.canvas-box.text-left, .canvas-box.text-right {text-align: center;}
.work-filter ul li {
    display: inline-block;
    margin: 0;
}
.img-full-img{margin-left: -15px;}
.work-filter ul li a{padding:6px 8px;}
#blog-slider .owl-prev{left:0;}
#blog-slider .owl-next{right: 0;}

#footer {
    text-align: center;
}
.footer-share {
    float: inherit!important;
    margin: 0 0 10px;
}
.col-xs-6{width:100%;}

.video-block { top: 30%;}
.vd-sm-caption {font-size: 20px;}
.vd-md-caption.text-center {
    font-size: 28px;
    font-weight: 300;
    line-height: 44px;
}
.video-block .btn {
    color: $white;
    float: left;
    font-size: 11px;
    margin: 30px 10px;
    width: 41%;
}
.work-filter ul li::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
}
.work-filter ul li a {
    color: #a1a1a1;
    display: inline;
    font-size: 13px;}
  #skills-bar {
    padding: 0;
}

h2 {
    font-size: 21px;
}
}
@media only screen and (min-width:0px) and (max-width: 480px) {
  #services .col-md-4.col-sm-4 {
    float: left;width:100%;
  }
  #moduleControl{display: none;}
}
@media only screen and (min-width:480px) and (max-width: 768px) {
  .col-md-4.col-sm-4 {
    float: left;
    width: 50%;
  }
  #moduleControl{display: none;}
  .team-wrap {
    background-color: $white;
    padding: 10px 10px 50px;
    margin-bottom: 30px;
  }
  .header-crystal #navigation.affix-top {
    background-color: #000000;
  }
}
@media only screen and (min-width:530px) and (max-width: 768px) {
  .header-crystal #navigation.affix-top {
    background-color: #000;
  }
  .navbar-default .navbar-nav > li {
      margin: 0 11px;
  }
  .navbar-default .navbar-nav > li > a{padding:12px 0}
  #blog-slider .owl-prev{left:0;}
  #blog-slider .owl-next{right: 0;}
  #moduleControl{display:none;}
}

@media only screen and (min-width:769px) and (max-width: 964px) {
  .navbar-default .navbar-nav > li {
    margin: 0 11px;
  }
  .navbar-default .navbar-nav > li > a{padding:12px 0}
}