/* Colors */
$red: #ff072a;
$yellow: #e8dd56;
$orange: #ffb035; //#ffc000;
$green: #82b440;
$blue: #2094f2;
$megna: #00897b;
$teal: #65e0b8;
$cyan: #65dae0;
$indigo: #3a589b;
$purple: #8360a8;
$grey: #a4a4a4;
$raspberry: #b51156;

$white: #ffffff;
$black: #000000;
$muted: #aaaaaa;

$grey-1: #111111;
$grey-2: #222222;
$grey-3: #333333;
$grey-4: #444444;
$grey-5: #555555;
$grey-6: #666666;
$grey-7: #777777;
$grey-8: #888888;
$grey-9: #999999;

$primary: #ff072a;
$secondary: #ffb035;


.switcher-box.default {
    background-color:#ff072a;
}
.switcher-box.style-1 {
    background-color: #2094f2;
}
.switcher-box.style-2 {
    background-color: #82b440;
}
.switcher-box.style-3 {
    background-color: #8360a8;
}
.switcher-box.style-4 {
    background-color: #ffc000;
}
.switcher-box.style-5 {
    background-color: #3a589b;
}