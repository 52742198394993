
/* Spacing and sizing css file */

/* margin */
.m-0 { margin: 0 !important; }
.mt-0,
.my-0 { margin-top: 0 !important; }
.mr-0,
.mx-0 { margin-right: 0 !important; }
.mb-0,
.my-0 { margin-bottom: 0 !important; }
.ml-0,
.mx-0 { margin-left: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.mt-1,
.my-1 { margin-top: 0.25rem !important; }
.mr-1,
.mx-1 { margin-right: 0.25rem !important; }
.mb-1,
.my-1 { margin-bottom: 0.25rem !important; }
.ml-1,
.mx-1 { margin-left: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.mt-2,
.my-2 { margin-top: 0.5rem !important; }
.mr-2,
.mx-2 { margin-right: 0.5rem !important; }
.mb-2,
.my-2 { margin-bottom: 0.5rem !important; }
.ml-2,
.mx-2 { margin-left: 0.5rem !important; }
.m-3 { margin: 1rem !important; }
.mt-3,
.my-3 { margin-top: 1rem !important; }
.mr-3,
.mx-3 { margin-right: 1rem !important; }
.mb-3,
.my-3 { margin-bottom: 1rem !important; }
.ml-3,
.mx-3 { margin-left: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.mt-4,
.my-4 { margin-top: 1.5rem !important; }
.mr-4,
.mx-4 { margin-right: 1.5rem !important; }
.mb-4,
.my-4 { margin-bottom: 1.5rem !important; }
.ml-4,
.mx-4 { margin-left: 1.5rem !important; }
.m-5 { margin: 3rem !important; }
.mt-5,
.my-5 { margin-top: 3rem !important; }
.mr-5,
.mx-5 { margin-right: 3rem !important; }
.mb-5,
.my-5 { margin-bottom: 3rem !important; }
.ml-5,
.mx-5 { margin-left: 3rem !important; }
.m-6 { margin: 4.5rem !important; }
.mt-6,
.my-6 { margin-top: 4.5rem !important; }
.mr-6,
.mx-6 { margin-right: 4.5rem !important; }
.mb-6,
.my-6 { margin-bottom: 4.5rem !important; }
.ml-6,
.mx-6 { margin-left: 4.5rem !important; }
.m-7 { margin: 7.5rem !important; }
.mt-7,
.my-7 { margin-top: 7.5rem !important; }
.mr-7,
.mx-7 { margin-right: 7.5rem !important; }
.mb-7,
.my-7 { margin-bottom: 7.5rem !important; }
.ml-7,
.mx-7 { margin-left: 7.5rem !important; }

/* padding */
.p-0 { padding: 0 !important; }
.pt-0,
.py-0 { padding-top: 0 !important; }
.pr-0,
.px-0 { padding-right: 0 !important; }
.pb-0,
.py-0 { padding-bottom: 0 !important; }
.pl-0,
.px-0 { padding-left: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.pt-1,
.py-1 { padding-top: 0.25rem !important; }
.pr-1,
.px-1 { padding-right: 0.25rem !important; }
.pb-1,
.py-1 { padding-bottom: 0.25rem !important; }
.pl-1,
.px-1 { padding-left: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.pt-2,
.py-2 { padding-top: 0.5rem !important; }
.pr-2,
.px-2 { padding-right: 0.5rem !important; }
.pb-2,
.py-2 { padding-bottom: 0.5rem !important; }
.pl-2,
.px-2 { padding-left: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.pt-3,
.py-3 { padding-top: 1rem !important; }
.pr-3,
.px-3 { padding-right: 1rem !important; }
.pb-3,
.py-3 { padding-bottom: 1rem !important; }
.pl-3,
.px-3 { padding-left: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.pt-4,
.py-4 { padding-top: 1.5rem !important; }
.pr-4,
.px-4 { padding-right: 1.5rem !important; }
.pb-4,
.py-4 { padding-bottom: 1.5rem !important; }
.pl-4,
.px-4 { padding-left: 1.5rem !important; }
.p-5 { padding: 3rem !important; }
.pt-5,
.py-5 { padding-top: 3rem !important; }
.pr-5,
.px-5 { padding-right: 3rem !important; }
.pb-5,
.py-5 { padding-bottom: 3rem !important; }
.pl-5,
.px-5 { padding-left: 3rem !important; }
.p-6 { padding: 4.5rem !important; }
.pt-6,
.py-6 { padding-top: 4.5rem !important; }
.pr-6,
.px-6 { padding-right: 4.5rem !important; }
.pb-6,
.py-6 { padding-bottom: 4.5rem !important; }
.pl-6,
.px-6 { padding-left: 4.5rem !important; }
.p-7 { padding: 7.5rem !important; }
.pt-7,
.py-7 { padding-top: 7.5rem !important; }
.pr-7,
.px-7 { padding-right: 7.5rem !important; }
.pb-7,
.py-7 { padding-bottom: 7.5rem !important; }
.pl-7,
.px-7 { padding-left: 7.5rem !important; }

/* negative margin */
.m-n1 { margin: -0.25rem !important; }
.mt-n1,
.my-n1 { margin-top: -0.25rem !important; }
.mr-n1,
.mx-n1 { margin-right: -0.25rem !important; }
.mb-n1,
.my-n1 { margin-bottom: -0.25rem !important; }
.ml-n1,
.mx-n1 { margin-left: -0.25rem !important; }
.m-n2 { margin: -0.5rem !important; }
.mt-n2,
.my-n2 { margin-top: -0.5rem !important; }
.mr-n2,
.mx-n2 { margin-right: -0.5rem !important; }
.mb-n2,
.my-n2 { margin-bottom: -0.5rem !important; }
.ml-n2,
.mx-n2 { margin-left: -0.5rem !important; }
.m-n3 { margin: -1rem !important; }
.mt-n3,
.my-n3 { margin-top: -1rem !important; }
.mr-n3,
.mx-n3 { margin-right: -1rem !important; }
.mb-n3,
.my-n3 { margin-bottom: -1rem !important; }
.ml-n3,
.mx-n3 { margin-left: -1rem !important; }
.m-n4 { margin: -1.5rem !important; }
.mt-n4,
.my-n4 { margin-top: -1.5rem !important; }
.mr-n4,
.mx-n4 { margin-right: -1.5rem !important; }
.mb-n4,
.my-n4 { margin-bottom: -1.5rem !important; }
.ml-n4,
.mx-n4 { margin-left: -1.5rem !important; }
.m-n5 { margin: -3rem !important; }
.mt-n5,
.my-n5 { margin-top: -3rem !important; }
.mr-n5,
.mx-n5 { margin-right: -3rem !important; }
.mb-n5,
.my-n5 { margin-bottom: -3rem !important; }
.ml-n5,
.mx-n5 { margin-left: -3rem !important; }

/* px margin */
.mt-10,
.my-10 { margin-top: 10px !important; }
.mb-10,
.my-10 { margin-bottom: 10px !important; }
.mt-20,
.my-20 { margin-top: 20px !important; }
.mb-20,
.my-20 { margin-bottom: 20px !important; }
.mt-30,
.my-30 { margin-top: 30px !important; }
.mb-30,
.my-30 { margin-bottom: 30px !important; }
.mt-40,
.my-40 { margin-top: 40px !important; }
.mb-40,
.my-40 { margin-bottom: 40px !important; }
.mt-50,
.my-50 { margin-top: 50px !important; }
.mb-50,
.my-50 { margin-bottom: 50px !important; }
.mt-60,
.my-60 { margin-top: 60px !important; }
.mb-60,
.my-60 { margin-bottom: 60px !important; }
.mt-80,
.my-80 { margin-top: 80px !important; }
.mb-80,
.my-80 { margin-bottom: 80px !important; }
.mt-100,
.my-100 { margin-top: 100px !important; }
.mb-100,
.my-100 { margin-bottom: 100px !important; }
.mt-150,
.my-150 { margin-top: 150px !important; }
.mb-150,
.my-150 { margin-bottom: 150px !important; }
.mt-200,
.my-200 { margin-top: 200px !important; }
.mb-200,
.my-200 { margin-bottom: 200px !important; }

/* px padding */
.pt-10,
.py-10 { padding-top: 10px !important; }
.pb-10,
.py-10 { padding-bottom: 10px !important; }
.pt-20,
.py-20 { padding-top: 20px !important; }
.pb-20,
.py-20 { padding-bottom: 20px !important; }
.pt-30,
.py-30 { padding-top: 30px !important; }
.pb-30,
.py-30 { padding-bottom: 30px !important; }
.pt-40,
.py-40 { padding-top: 40px !important; }
.pb-40,
.py-40 { padding-bottom: 40px !important; }
.pt-50,
.py-50 { padding-top: 50px !important; }
.pb-50,
.py-50 { padding-bottom: 50px !important; }
.pt-60,
.py-60 { padding-top: 60px !important; }
.pb-60,
.py-60 { padding-bottom: 60px !important; }
.pt-80,
.py-80 { padding-top: 80px !important; }
.pb-80,
.py-80 { padding-bottom: 80px !important; }
.pt-100,
.py-100 { padding-top: 100px !important; }
.pb-100,
.py-100 { padding-bottom: 100px !important; }
.pt-150,
.py-150 { padding-top: 150px !important; }
.pb-150,
.py-150 { padding-bottom: 150px !important; }
.pt-200,
.py-200 { padding-top: 200px !important; }
.pb-200,
.py-200 { padding-bottom: 200px !important; }

/* margin auto */
.m-auto { margin: auto !important; }
.mt-auto,
.my-auto { margin-top: auto !important; }
.mr-auto,
.mx-auto { margin-right: auto !important; }
.mb-auto,
.my-auto { margin-bottom: auto !important; }
.ml-auto,
.mx-auto { margin-left: auto !important; }

/* width */
.w-auto { width: auto !important; }
.w-10 { width: 10% !important; }
.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-40 { width: 40% !important; }
.w-50 { width: 50% !important; }
.w-60 { width: 60% !important; }
.w-70 { width: 70% !important; }
.w-80 { width: 80% !important; }
.w-90 { width: 90% !important; }
.w-100 { width: 100% !important; }

/* height */
.h-auto { height: auto !important; }
.height-10 { height: 10vh !important; }
.height-20 { height: 20vh !important; }
.height-30 { height: 30vh !important; }
.height-40 { height: 40vh !important; }
.height-50 { height: 50vh !important; }
.height-60 { height: 60vh !important; }
.height-70 { height: 70vh !important; }
.height-80 { height: 80vh !important; }
.height-90 { height: 90vh !important; }
.height-100 { height: 100vh !important; }